lea<template>
  <div class="space-y-8">
    <!-- Name Section -->
    <section id="rule-name">
      <TitleSection title="Rule Name" />
      <RuleInput v-model="name" :isValid="isValidName" />
    </section>
    <!-- Match List -->
    <section id="rule-match-list">
      <TitleWithInfoSection title="Regular Expression to Detect" :info="UIS_MATCH_LIST_INFO" />
      <MatchList 
        :matchListDefault="regexListDefault" 
        emptyMessage="Add regular expressions (RegEx) above."
        :isValid="isValidMatch"
        @action="regexListWatcher"
      />
    </section>
    <!-- Location Section -->
    <section id="rule-locations">
      <TitleWithInfoSection title="Coverage" :info="UIS_COVERAGE_INFO" />
      <RuleEnumSelect v-model="locations" :options="CONTENT_LOCATION" @action="locationsListWatcher" />
    </section>
    <!-- Settings Section -->
    <section id="rule-settings">
      <TitleWithInfoSection title="Settings" :info="UIS_SETTINGS_INFO" />
      <div class="space-y-0.5">
        <RuleToggle v-model="status" label="Rule" />
        <RuleToggle v-model="logging" label="Logging" />
      </div>
    </section>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import RuleInput from "../slideover/RuleInput.vue";
import TitleSection from "../slideover/TitleSection.vue";
import TitleWithInfoSection from "../slideover/TitleWithInfoSection.vue";
import RuleToggle from "../slideover/RuleToggle.vue";
import MatchList from "../slideover/MatchList.vue";
import RuleEnumSelect from "../slideover/RuleEnumSelect.vue";

import { ContentLocation as CONTENT_LOCATION } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

import {
  Logging as LOGGING,
  Status as STATUS,
} from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

import { useV3RulesStore } from "../../store/rulesStore.js";
const RulesStore = useV3RulesStore();

const statusToBool = (status) => {
  if (status == STATUS.STATUS_ENABLED) return true;
  if (status == STATUS.STATUS_DISABLED) return false;
  return null;
};

const loggingToBool = (logging) => {
  if (logging == LOGGING.LOGGING_ENABLED) return true;
  if (logging == LOGGING.LOGGING_DISABLED) return false;
  return null;
};

// ui - populate initial values
const name = ref(RulesStore.activeRule.getName());
const status = ref(statusToBool(RulesStore.activeRule.getStatus()));
const logging = ref(loggingToBool(RulesStore.activeRule.getLogging()));
const locations = ref([]);
const regexListDefault = ref([]);

// set the default locations to all locations
if(RulesStore.slideOverType == 'create'){
  locations.value = [
    CONTENT_LOCATION.CONTENT_LOCATION_SUBJECT,
    CONTENT_LOCATION.CONTENT_LOCATION_BODY,
    CONTENT_LOCATION.CONTENT_LOCATION_FILENAMES
  ];

  // set the current active rule
  const _activeRule = RulesStore.activeRule;
  const _DlpRegexMatching = _activeRule.getDlpregexmatching();
  _DlpRegexMatching.setLocationsList(locations.value);

}else if(RulesStore.slideOverType == 'edit'){
  // set current rule data locations
  const _activeRule = RulesStore.activeRule;
  const _DlpRegexMatching = _activeRule.getDlpregexmatching();
  let _locations = _DlpRegexMatching.getLocationsList();
  locations.value = _locations

  // set current rule data matchlist
  let _regexList = _DlpRegexMatching.getRegexesList();
  regexListDefault.value = _regexList

}

// watchers to update
watch(name, (newValue) => {
  RulesStore.flagActiveRuleAsModified();
  if (isValidName(newValue)) {
    let _activeRule = RulesStore.activeRule;
    _activeRule.setName(newValue);
    RulesStore.setActiveRule(_activeRule);
  }
});

watch(status, (newValue) => {
  RulesStore.flagActiveRuleAsModified();
  let _activeRule = RulesStore.activeRule;
  _activeRule.setStatus(
    newValue ? STATUS.STATUS_ENABLED : STATUS.STATUS_DISABLED
  );
  RulesStore.setActiveRule(_activeRule);
});

watch(logging, (newValue) => {
  RulesStore.flagActiveRuleAsModified();
  let _activeRule = RulesStore.activeRule;
  _activeRule.setLogging(
    newValue ? LOGGING.LOGGING_ENABLED : LOGGING.LOGGING_DISABLED
  );
  RulesStore.setActiveRule(_activeRule);
});

const regexListWatcher = (regexList) => {
  console.log('regexListWatcher fired')
  RulesStore.flagActiveRuleAsModified();
  const _activeRule = RulesStore.activeRule;
  const _DlpRegexMatching = _activeRule.getDlpregexmatching();
  // _DlpRegexMatching.setLocationsList(locations.value);
  console.log(_DlpRegexMatching)
  _DlpRegexMatching.setRegexesList(regexList);
  _activeRule.setDlpregexmatching(_DlpRegexMatching);
  RulesStore.setActiveRule(_activeRule);
}

const locationsListWatcher = (locationsList) => {
  regexListDefault.value = locationsList;
  console.log('locationsListWatcher fired')
  RulesStore.flagActiveRuleAsModified();
  const _activeRule = RulesStore.activeRule;
  const _DlpRegexMatching = _activeRule.getDlpregexmatching();
  _DlpRegexMatching.setLocationsList(locationsList);
  _activeRule.setDlpregexmatching(_DlpRegexMatching);
  RulesStore.setActiveRule(_activeRule);
}

// ui strings
const UIS_SETTINGS_INFO = [
  "You can enable and disable rules with the first toggle switch. Rules are disabled by default when newly created.", 
  "Logging of end-user data can be enabled and disabled with the second toggle switch. Enabled Rules with logging enabled will collect data that is available in the Logs tab of the Admin Dashboard."
]
const UIS_MATCH_LIST_INFO = ["Enter individual regular expressions (RegEx) this rule will scan for below."];
const UIS_COVERAGE_INFO = ["The toggle switches below enables/disables the email components this rule should scan."];

// validation
const isValidName = (item) => {
  if (
    item.trim() == "" ||
    item == undefined ||
    item == null
  ) {
    return {
      isValid: false,
      errorMessage: "You entered an empty string",
    };
  } else if (item.length < 4) {
    return {
      isValid: false,
      errorMessage: "Please input more than 3 characters.",
    };
  } else if (RulesStore.ruleNames.includes(item) && item != RulesStore.activeRule.getName()) {
    return {
      isValid: false,
      errorMessage: "This name already exists.",
    };
  } else {
    return {
      isValid: true,
    };
  }
};


// validation
const isValidMatch = (item) => {

// check if empty
if (
  item.trim() == "" ||
  item == undefined ||
  item == null
) {
  return {
    isValid: false,
    errorMessage: "You entered an empty string",
  };
}
// check if it already exist
else if (regexListDefault.value.includes(item)) {
  return {
    isValid: false,
    errorMessage: "This match is already in the list.",
  };
} 
// check if it already exist
else if (!checkIfValidRegEx(item)) {
  return {
    isValid: false,
    errorMessage: "This is an invalid Regular Expression format.",
  };
} 
else {
  return {
    isValid: true,
  };
}
};

const checkIfValidRegEx = (item) => {
  console.log('checkIfValidRegEx fired')
  try {
    let x= new RegExp(item);
    console.log(x)
    return true
  } catch(e) {
    console.error('Invalid RegEx: ', e)
    return false
  }
}

</script>