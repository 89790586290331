<template>

  <div v-if="SessionStore.state == STATE.INITIALIZING">
    <!-- TODO -->
  </div>
  <div v-else>

    <!-- Tab Menu -->
    <div class="h-9 my-2 flex justify-between items-center space-x-4">
      <ActionButtonGroup v-if="selectedData.length > 0" :menu="context_menu" />
      <div v-else><span class="sr-only">For balancing menu</span></div>
      <ActionButtonGroup :menu="action_menu" />
    </div>

    <div v-if="RulesStore.rulesForUi.length > 0" class="overflow-show border border-gray-300 mb-5 mt-4">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50 h-12">
          <tr class="text-sm font-semibold text-gray-700">
            <th scope="col" class="py-3.5 pr-1 text-left pl-6 w-4">
              <GroupCheckbox
                v-model="isAllSelected"
                @action="handleGroupSelectAllClick"
              />
            </th>
            <th scope="col" class="px-1 py-3.5 text-left w-4">
              <!-- TODO: CLEAN THIS UP -->
              <!-- <GroupToggle
                v-model="isAllEnabled"
                @action="handleGroupToggleAllClick"
              /> -->
            </th>
            <th scope="col" class="px-1 py-3.5 text-left w-4">
              <!-- <GroupToggle
                v-model="isAllEnabled"
                @action="handleGroupToggleAllClick"
              /> -->
            </th>
            <th scope="col" class="px-3 py-3.5 text-left">
              Rule Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left">
              Rule Type
            </th>
            <th scope="col" class="px-3 py-3.5 text-left">
              Date Created
            </th>
            <th scope="col" class="relative py-3.5 pl-3 pr-6 w-2">
              <span class="sr-only">Edit</span>
            </th>
            <th scope="col" class="relative py-3.5 pl-3 pr-6 w-2">
              <span class="sr-only">Delete</span>
            </th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="(item, index) in RulesStore.rulesForUi" :key="`item-${index}`">
            <td
              class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-4"
            >
              <Checkbox
                v-model="item.isSelected"
                :data="item"
                @action="handleItemSelectClick"
              />
            </td>
            <td class="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-4 flex flex-col mr-4 space-y-1">
              <button :disabled="index==0" @click="moveUpPriority(index)" title="Move up priority." class="text-gray-500 w-4 h-4 hover:opacity-50 disabled:opacity-25 disabled:cursor-default disabled:pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
              </button>
              <button :disabled="index==RulesStore.rulesForUi.length-1" @click="moveDownPriority(index)" title="Move down priority." class="text-gray-500 w-4 h-4 hover:opacity-50 disabled:opacity-25 disabled:cursor-default disabled:pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </button>
            </td>
            <td class="whitespace-nowrap px-1 py-2 text-sm text-gray-500 w-4">
              <Toggle
                v-model="item.isEnabled"
                :data="item"
                @action="handleItemStatusToggle"
              />
            </td>
            <td :title="item.priority" class="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-1/2">
              {{ item.entry }}
            </td>
            <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-1/2">
              <span 
                :class="`inline-flex items-center rounded-full bg-${item.color}-100 px-3 py-1 text-xs text-${item.color}-700`">
                {{ item.info }}
              </span>
            </td>
            <td  class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
              {{ getDateFromObjectId(item.id) }}
            </td>
            <td
              class="relative whitespace-nowrap py-2 px-0 text-right text-sm font-medium w-2"
            >
              <!-- <TableAnchor
                :data="index"
                color="blue"
                text="Edit"
                @action="handleItemEditClick"
              /> -->
              <button 
                  @click="handleItemEditClick(index)"
                    type="button" 
                    class="text-gray-600">
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>
                </button>
            </td>
            <td
              class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium w-2"
            >
              <button 
                @click="handleItemDeleteClick(index)"
                type="button" 
                class="text-red-600">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
              </button>
              <!-- <TableAnchor
                :data="index"
                color="red"
                text="Delete"
                @action="handleItemDeleteClick"
              /> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="overflow-show border border-gray-300 mb-5 mt-4 h-48 flex items-center justify-center text-sm text-gray-500">
      There are no rules yet. Start adding rules by creating new ones above.
    </div>

    <ModalConfirmDelete
      :show="showConfirmationModal"
      @confirm="initializeDelete"
      @cancel="showConfirmationModal = false, selectedIndexForDelete = null"
    />

    <ModalConfirmDeleteAll
      :show="showConfirmationModalDeleteMultiple"
      @confirm="initializeDeleteMultiple"
      @cancel="showConfirmationModalDeleteMultiple = false"
    />

  </div>

</template>
<script setup>
import { ref, computed } from "vue";
import { STATE } from "../data/enums";
import moment from "moment";

import TableAnchor from "./TableAnchor.vue";
import ActionButtonGroup from "./ActionButtonGroup.vue";
import Toggle from "./Toggle.vue";
import Checkbox from "./Checkbox.vue";
import GroupToggle from "./GroupToggle.vue";
import GroupCheckbox from "./GroupCheckbox.vue";
import ModalConfirmDelete from "./ModalConfirmDelete.vue";
import ModalConfirmDeleteAll from "./ModalConfirmDeleteAll.vue";

import EnableIcon from "../icons/EnableIcon.vue";
import DisableIcon from "../icons/DisableIcon.vue";
import DeleteIcon from "../icons/DeleteIcon.vue";
import RefreshIcon from "../icons/RefreshIcon.vue";
import FilterIcon from "../icons/FilterIcon.vue";
import PlusIcon from "../icons/PlusIcon.vue";

import { Status as STATUS } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

// Store Dependencies
import { useV3RulesStore } from "../store/rulesStore.js";
import { useV3SessionStore } from "../store/sessionStore.js";
const RulesStore = useV3RulesStore();
const SessionStore = useV3SessionStore();

const showConfirmationModal = ref(false);
const showConfirmationModalDeleteMultiple = ref(false);
const selectedIndexForDelete = ref(null);


const selectedData = computed(() => {
  return RulesStore.rulesForUi.filter((item) => item.isSelected);
});

const enabledData = computed(() => {
  return RulesStore.rulesForUi.filter((item) => item.isEnabled);
});

const isAllSelected = computed(() => {
  let selectedItems = RulesStore.rulesForUi.filter((item) => item.isSelected);
  return selectedItems.length == RulesStore.rulesForUi.length ? true : false;
});

const isAllEnabled = computed(() => {
  let enabledItems = RulesStore.rulesForUi.filter((item) => item.isEnabled);
  return enabledItems.length == RulesStore.rulesForUi.length ? true : false;
});

/**
 * Table Actions
 */

const handleItemEditClick = (index) => {
  console.log("executing handleItemEditClick");
  console.log(`Setting active Rule Data as: `, RulesStore.rules[index].getData().toObject());
  // show the rule slideover
  RulesStore.setActiveRulePayload(RulesStore.rules[index]);
  RulesStore.setActiveRule(RulesStore.rules[index].getData());
  RulesStore.setSlideOverType('edit');
  RulesStore.openSlideOver();
};

const handleItemDeleteClick = async (index) => {

  // pushDeleteRule
  console.log("executing handleItemDeleteClick for ", index);
  selectedIndexForDelete.value = index;
  showConfirmationModal.value = true;
  
};

const initializeDelete = async () => {
  showConfirmationModal.value = false;
  // TODO: REFACTOR THIS
  try {
    SessionStore.setToWaitingState();
    await RulesStore.pushDeleteRule(SessionStore.traceId, RulesStore.rules[selectedIndexForDelete.value].getObjectId().getValue());
    // await RulesStore.pushListRule(SessionStore.traceId);

    // update the store rules here
    RulesStore.rules.splice(selectedIndexForDelete.value, 1);
    RulesStore.rulesForUi.splice(selectedIndexForDelete.value, 1);
    
    SessionStore.setToReadyState();

    selectedIndexForDelete.value = null;
  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
}

const initializeDeleteMultiple = async () => {
  showConfirmationModalDeleteMultiple.value = false;

  SessionStore.setToWaitingState();
  try {
    // make sure we await each pushUpdateRule. To do this, we use promise all.
    await Promise.all(
      selectedData.value.map(async (rule) => {
        await RulesStore.pushDeleteRule(SessionStore.traceId, rule.id);
      })
    );
    // await RulesStore.pushListRule(SessionStore.traceId);
    
    // update the store rules here
    selectedData.value.forEach( selectedRule => {
      
      let index1 = RulesStore.rules.findIndex( (storeRule) => (storeRule.id == selectedRule.id));
      let index2 = RulesStore.rulesForUi.findIndex( (storeRule) => (storeRule.id == selectedRule.id));

      RulesStore.rules.splice(index1, 1);
      RulesStore.rulesForUi.splice(index2, 1);

    });


    SessionStore.setToReadyState();
  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
}

const handleItemStatusToggle = async (data) => {
  console.log("executing handleItemStatusToggle for ", data);
  console.log("items.value", RulesStore.rulesForUi);
  console.log("enabledData.value", enabledData.value);

  SessionStore.setToWaitingState();
  let _ruleIndex = RulesStore.rules.findIndex( _r => _r.getObjectId().getValue() == data.id);
  RulesStore.setActiveRulePayload(RulesStore.rules[_ruleIndex]);
  RulesStore.setActiveRule(RulesStore.rules[_ruleIndex].getData());
  RulesStore.activeRule.setStatus(data.isEnabled ? STATUS.STATUS_ENABLED : STATUS.STATUS_DISABLED);

  try {
    await RulesStore.pushUpdateRule(SessionStore.traceId);

    RulesStore.setActiveRulePayload(null);
    RulesStore.setActiveRule(null);

    SessionStore.setToReadyState();
  } catch (err) {
    // revert
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }

};

const handleItemSelectClick = (data) => {
  console.log("executing handleItemSelectClick for ", data);
  console.log("items.value", RulesStore.rulesForUi);
  console.log("selectedData.value", selectedData.value);
};

const handleGroupSelectAllClick = () => {
  console.log("executing handleGroupSelectAllClick");
  console.log("isAllSelected", isAllSelected.value);

  if (!isAllSelected.value) {
    RulesStore.rulesForUi.forEach((item) => {
      item.isSelected = true;
    });
  } else {
    RulesStore.rulesForUi.forEach((item) => {
      item.isSelected = false;
    });
  }

  console.log(RulesStore.rulesForUi);
  console.log("isAllSelected", isAllSelected.value);
};

const handleGroupToggleAllClick = () => {
  console.log("executing handleGroupToggleAllClick");
  console.log("isAllEnabled", isAllEnabled.value);

  if (!isAllEnabled.value) {
    updateAllItemStatus(STATUS.STATUS_ENABLED);
    RulesStore.rulesForUi.forEach((item) => {
      item.isEnabled = true;
    });
  } else {
    updateAllItemStatus(STATUS.STATUS_DISABLED);
    RulesStore.rulesForUi.forEach((item) => {
      item.isEnabled = false;
    });
  }

  console.log(RulesStore.rulesForUi);
  console.log("isAllEnabled", isAllEnabled.value);
};

const updateAllItemStatus = async (status) => {
  SessionStore.setToWaitingState();
  try {
    // make sure we await each pushUpdateRule. To do this, we use promise all.
    await Promise.all(
      RulesStore.rules.map(async (rule) => {
        RulesStore.setActiveRulePayload(rule);
        RulesStore.setActiveRule(rule.getData());
        RulesStore.activeRule.setStatus(status);
        await RulesStore.pushUpdateRule(SessionStore.traceId);
      })
    );
  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
  SessionStore.setToReadyState();
}

/**
 * Context Menu
 */

const handleGroupEnableClick = async () => {
  console.log("executing handleGroupEnableClick for ", selectedData.value);

  SessionStore.setToWaitingState();
  try {
    await Promise.all(
      selectedData.value.map(async (rule) => {
        let _ruleIndex = RulesStore.rules.findIndex( _r => _r.getObjectId().getValue() == rule.id);
        RulesStore.setActiveRulePayload(RulesStore.rules[_ruleIndex]);
        RulesStore.setActiveRule(RulesStore.rules[_ruleIndex].getData());
        RulesStore.activeRule.setStatus(STATUS.STATUS_ENABLED);
        
        await RulesStore.pushUpdateRule(SessionStore.traceId);

        RulesStore.setActiveRulePayload(null);
        RulesStore.setActiveRule(null);
    
        rule.isSelected = false;
        rule.isEnabled = true;
      })
    );
  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
  SessionStore.setToReadyState();
  
};

const handleGroupDisableClick = async () => {
  console.log("executing handleGroupDisableClick for ", selectedData.value);

  SessionStore.setToWaitingState();
  try {
    await Promise.all(
      selectedData.value.map(async (rule) => {
        let _ruleIndex = RulesStore.rules.findIndex( _r => _r.getObjectId().getValue() == rule.id);
        RulesStore.setActiveRulePayload(RulesStore.rules[_ruleIndex]);
        RulesStore.setActiveRule(RulesStore.rules[_ruleIndex].getData());
        RulesStore.activeRule.setStatus(STATUS.STATUS_DISABLED);
        
        await RulesStore.pushUpdateRule(SessionStore.traceId);

        RulesStore.setActiveRulePayload(null);
        RulesStore.setActiveRule(null);
    
        rule.isSelected = false;
        rule.isEnabled = false;
      })
    );
  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
  SessionStore.setToReadyState();
  
};

const handleGroupDeleteClick = () => {
  console.log("executing handleGroupDeleteClick for ", selectedData.value);
  showConfirmationModalDeleteMultiple.value = true;
  
};

const context_menu = [
  {
    name: "Enable",
    icon: EnableIcon,
    color: "gray",
    action: handleGroupEnableClick,
  },
  {
    name: "Disable",
    icon: DisableIcon,
    color: "gray",
    action: handleGroupDisableClick,
  },
  {
    name: "Delete",
    icon: DeleteIcon,
    color: "red",
    action: handleGroupDeleteClick,
  },
];

/**
 * Action Menu
 */

const handleRefreshClick = async () => {
  console.log("executing handleRefreshClick");

  try {
    // TODO: activate and admin guard
    SessionStore.setToWaitingState();
    await SessionStore.setTraceId();
    await RulesStore.pushListRule(SessionStore.traceId);
    SessionStore.setToReadyState();
  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }

};

const moveUpPriority = async (index) => {
  
  SessionStore.setToWaitingState();
  try {

    let prio_current = RulesStore.rules[index].getData().getPriority();
    let prio_above = RulesStore.rules[index-1].getData().getPriority();

    console.log("switching prios: ")
    console.log({prio_above})
    console.log({prio_current})
    
    RulesStore.setActiveRulePayload(RulesStore.rules[index]);
    RulesStore.setActiveRule(RulesStore.rules[index].getData());
    RulesStore.activeRule.setPriority(prio_above);
    await RulesStore.pushUpdateRule(SessionStore.traceId);
    RulesStore.rulesForUi[index].priority = prio_above;

    RulesStore.setActiveRulePayload(RulesStore.rules[index-1]);
    RulesStore.setActiveRule(RulesStore.rules[index-1].getData());
    RulesStore.activeRule.setPriority(prio_current);
    await RulesStore.pushUpdateRule(SessionStore.traceId);
    RulesStore.rulesForUi[index-1].priority = prio_current;


  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
  SessionStore.setToReadyState();

}


const moveDownPriority = async (index) => {
  
  SessionStore.setToWaitingState();
  try {

    let prio_current = RulesStore.rules[index].getData().getPriority();
    let prio_below = RulesStore.rules[index+1].getData().getPriority();

    console.log("switching prios: ")
    console.log({prio_current})
    console.log({prio_below})
    
    RulesStore.setActiveRulePayload(RulesStore.rules[index]);
    RulesStore.setActiveRule(RulesStore.rules[index].getData());
    RulesStore.activeRule.setPriority(prio_below);
    await RulesStore.pushUpdateRule(SessionStore.traceId);
    RulesStore.rulesForUi[index].priority = prio_below;

    RulesStore.setActiveRulePayload(RulesStore.rules[index+1]);
    RulesStore.setActiveRule(RulesStore.rules[index+1].getData());
    RulesStore.activeRule.setPriority(prio_current);
    await RulesStore.pushUpdateRule(SessionStore.traceId);
    RulesStore.rulesForUi[index+1].priority = prio_current;

  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
  SessionStore.setToReadyState();

}

const handleCreateClick = () => {
  console.log("executing handleCreateClick");

  RulesStore.setActiveRulePayload(null);
  RulesStore.setActiveRule(null);

  RulesStore.setSlideOverType('create');
  RulesStore.openSlideOver();
};

var getDateFromObjectId = function (id) {
	return `${getDate(id)} ${getTime(id)}`;
};

function getDate(id) {
  let timestamp = id.toString().substring(0, 8);
  return moment.unix(parseInt(timestamp, 16)).format('YYYY-MM-DD')
}


function getTime(id) {

  let timestamp = id.toString().substring(0, 8);
  return moment.unix(parseInt(timestamp, 16)).utc().format('HH:mm z')
}

const action_menu = [
  {
    name: "Create New Rule",
    icon: PlusIcon,
    color: "blue",
    action: handleCreateClick,
  },
  {
    name: "Refresh List",
    icon: RefreshIcon,
    color: "gray",
    action: handleRefreshClick,
  },
];

</script>