<template>
  <div class="w-full">
    <!-- Input -->
    <div class="flex justify-between space-x-2 mt-1">
      <input
        v-model="formValue"
        @input="onUpdate()"
        @keypress.enter="addNewMatchToList()"
        type="text"
        :class="[
          errorMessage
            ? 'text-red-900 focus:border-red-500 focus:ring-red-500 border-red-300'
            : 'text-gray-900 focus:border-gray-500 focus:ring-gray-500 border-gray-300',
          'placeholder-gray-300 rounded-md block w-full pr-10 focus:outline-none sm:text-sm px-3 py-2',
        ]"
      />
      <button
        type="submit"
        @click="addNewMatchToList()"
        class="flex items-center space-x-1 text-sm disabled:opacity-75 disabled:cursor-default px-4 py-1.5 rounded flex items-center justify-center bg-blue-500 h-10 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>

        <!-- <span>Add</span> -->
      </button>
    </div>
    <!-- Error -->
    <section
      :class="[errorMessage ? 'visible' : 'invisible', 'mt-1 text-sm text-red-600']"
    >
      {{ errorMessage }}
    </section>
    <!-- Match List -->
    <div
      v-if="matchList.length > 0"
      class="flex py-2 flex-wrap space-x-2 -ml-2"
    >
      <!-- <span v-for="(match, matchIndex) in matchList" :key="match" class="py-0.5 text-xs text-gray-700 bg-gray-200 rounded-full px-2 my-1 ml-2">
                <button @click="removeMatchFromList(matchIndex, matchListIndex)">X</button>
              </span> -->

      <span
        v-for="(match, matchIndex) in matchList"
        :key="match"
        class="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500 items-center ml-2 my-1"
      >
        <span>{{ match }}</span>
        <button
          @click="removeMatchFromList(matchIndex)"
          type="button"
          class="group relative -mr-1 h-3 w-3 rounded-sm hover:bg-gray-300 text-center"
        >
          <span class="sr-only">Remove</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-3 h-3"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>

          <span class="absolute -inset-1" />
        </button>
      </span>
    </div>
    <!-- Empty Match List -->
    <div v-else class="py-2 text-xs text-gray-500 text-center w-full">
      <div
        class="border border-gray-300 h-20 flex items-center text-center justify-center"
      >
        {{ emptyMessage }}
      </div>
    </div>
  </div>
</template>
<script setup>

import { ref } from "vue";

const props = defineProps(["matchListDefault", "emptyMessage", "isValid"]);
const emits = defineEmits(["action"]);

const matchList = ref(props.matchListDefault);
const formValue = ref("");

const errorMessage = ref("");

const onUpdate = () => {
  if (formValue.value == "") {
    errorMessage.value = "";
  }
};

const addNewMatchToList = () => {

  let validationCheckRes = props.isValid(formValue.value);
  
  if(validationCheckRes.isValid){
    // if all goes well, update the match list
    let _matchList = matchList.value;
    _matchList.push(formValue.value);
    matchList.value = _matchList;

    publishChanges();

    // empty the UI
    errorMessage.value = "";
    formValue.value = "";
  }else{
    errorMessage.value = validationCheckRes.errorMessage
  }
  
};

const removeMatchFromList = (matchIndex) => {
  let _matchList = matchList.value;
  _matchList.splice(matchIndex, 1);
  matchList.value = _matchList;
};

const publishChanges = () => {
  console.log("Publishing changes")
  // update teh DLP Text Matching in parent
  emits('action', matchList.value) 
}

</script>