<template>

  <div>
    <TransitionRoot as="template" :show="RulesStore.isSlideOverOpen">
    <Dialog as="div" class="relative z-20" @close="(SessionStore.state == STATE.WAITING || SessionStore.state == STATE.INITIALIZING) ? null : handleCloseClick()">
      <!-- <div class="fixed inset-0" /> -->
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-lg">
                <div
                  class="flex h-full flex-col divide-y divide-gray-200 bg-gray-50 shadow-xl"
                  :class="
                    SessionStore.state == STATE.WAITING ||
                    SessionStore.state == STATE.INITIALIZING
                      ? 'pointer-events-none cursor-default'
                      : ''
                  "
                >
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-gray-800 h-16 flex items-centerpx-4 sm:px-6">
                      <div class="flex items-center justify-between w-full">
                        <DialogTitle
                          class="text-base font-medium text-gray-200"
                        >
                          {{
                            RulesStore.slideOverType == "create"
                              ? "Create New Rule"
                              : "Edit Rule"
                          }}
                        </DialogTitle>

                        <div class="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            class="rounded-md bg-gray-800 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="handleCloseClick()"
                          >
                            <span class="sr-only">Close panel</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Content  -->
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="px-4 sm:px-6 py-8">
                        <!-- Implementation Section -->

                        
                        <div v-if="RulesStore.slideOverType == 'create' && !RulesStore.activeRule">
                          <h3 class="block text-base font-medium leading-6 text-gray-900">
                            Select Rule Type
                          </h3>
                          <CreateRuleOptions />
                        </div>
                        <div v-else>
                         
                          <div class="mb-6">

                            <button v-if="RulesStore.slideOverType == 'create'" @click="handleTypeChangeClick" type="button" class="flex items-center mb-6 disabled:opacity-50 disabled:cursor-default rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1 -ml-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                              </svg>
                              <span>Select Different Rule Type</span>
                            </button>

                            <div class="flex space-x-1 items-center">
                              <div class="flex w-full items-center justify-between mb-2">
                                <div class="flex items-center space-x-1">
                                  <h3 class="block text-base font-medium leading-6 text-gray-900">
                                    Rule Type
                                  </h3>
                                  <InfoIcon @click="isRuleTypeInfoToggled = !isRuleTypeInfoToggled" class="w-4 h-4 text-gray-500 cursor-pointer" />
                                </div>
                                
                              </div>
                            </div>

                            <div>
                              
                            </div>

                            <div class="text-gray-700 rounded-md block w-full text-sm py-2">
                              {{ getImplementationUiData.text }}
                            </div>
                            <div v-if="isRuleTypeInfoToggled" 
                              :class="`text-sm font-normal text-${getImplementationUiData.motif}-700 w-full bg-${getImplementationUiData.motif}-100 p-5 rounded-md mb-4 space-y-4 mt-3`">
                              {{ getImplementationUiData.description }}
                              <div class="w-full">
                                <span v-for="tag in getImplementationUiData.tags" :key="tag" 
                                  class="inline-flex items-center rounded-md px-2 py-0.5 text-xs ring-1 ring-inset mr-2 mt-2"
                                  :class="[
                                    (getImplementationUiData.motif == 'purple') ? 'ring-purple-400 text-purple-700 ' : '',
                                    (getImplementationUiData.motif == 'lime') ? 'ring-lime-600 text-lime-700 ' : '',
                                    (getImplementationUiData.motif == 'orange') ? 'ring-orange-400 text-orange-700 ' : '',
                                  ]">
                                  {{ tag }}
                                </span>
                              </div>
                            </div>
                            

                          </div>
                          
                          
                        
                          <!-- Show the Component for the Rule Data -->
                          <component
                            :class="(SessionStore.state == STATE.WAITING) ? 'opacity-50 pointer-events-none cursor-default' : ''"
                            :is="IMPLEMENTATIONS_COMPONENT_MAP[getImplementation].component"
                          />
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div v-if="RulesStore.activeRule" class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      :disabled="SessionStore.state == STATE.WAITING"
                      @click="handleCloseClick()"
                      type="button"
                      class="disabled:opacity-50 disabled:cursor-default rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      :disabled="SessionStore.state == STATE.WAITING || RulesStore.isReadyToProceed(RulesStore.activeRule)"
                      v-if="RulesStore.activeRule.getStatus() == STATUS.STATUS_DISABLED && RulesStore.slideOverType == 'create'"
                      @click="handleSaveAndEnableClick()"
                      type="button"
                      class="disabled:opacity-50 disabled:cursor-default ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Save and Enable
                    </button>
                    <button
                      :disabled="SessionStore.state == STATE.WAITING || RulesStore.isReadyToProceed(RulesStore.activeRule)"
                      @click="handleSaveClick()"
                      type="button"
                      class="disabled:opacity-50 disabled:cursor-default ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      {{ (RulesStore.slideOverType == 'create') ? 'Save' : 'Update'}} 
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <ModalConfirmExit
    :show="showConfirmationModal"
    @confirm="closeEverything"
    @cancel="showConfirmationModal = false"
  />

  <ModalConfirmExit
    :show="showConfirmationToChangeTypeModal"
    @confirm="proceedWithTypeChange"
    @cancel="showConfirmationToChangeTypeModal = false"
  />

  </div>
  
</template>
  
<script setup>

import { ref, computed } from "vue";

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { Status as STATUS } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { STATE } from "../data/enums";

// implementation components
import DLPTextMatchingComponent from "./implementation/DLPTextMatching.vue";
import DLPRegexMatchingComponent from "./implementation/DLPRegexMatching.vue";
import AllowedEmailCombinationsComponent from "./implementation/AllowedEmailCombinations.vue";
import AllowedDomainCombinationsComponent from "./implementation/AllowedDomainCombinations.vue";
import DeniedEmailCombinationsComponent from "./implementation/DeniedEmailCombinations.vue";
import DeniedDomainCombinationsComponent from "./implementation/DeniedDomainCombinations.vue";
import CompliantEmailCombinationsComponent from "./implementation/CompliantEmailCombinations.vue";

import CreateRuleOptions from "./CreateRuleOptions.vue";
import ModalConfirmExit from "./ModalConfirmExit.vue";

import InfoIcon from "../icons/InfoIcon.vue";
import EditIcon from "../icons/EditIcon.vue";

// Store Dependencies
import { useV3RulesStore } from "../store/rulesStore.js";
import { useV3SessionStore } from "../store/sessionStore.js";
const RulesStore = useV3RulesStore();
const SessionStore = useV3SessionStore();

const showConfirmationModal = ref(false);
const isRuleTypeInfoToggled = ref(false);
const selectedRuleTypeForCreate = ref(false);

const showConfirmationToChangeTypeModal = ref(false);

console.log('selectedRuleTypeForCreate', selectedRuleTypeForCreate.value)

// TODO: Convert this to map
const IMPLEMENTATIONS_COMPONENT_MAP = [
  {
    name: "Denied Domain Combinations",
    text: "Risky Domain Name Combinations",
    description: "When more than one domain name specified in a customer-defined group is represented in an email's recipients.",
    tags: ['Risky Recipients', 'Domain Name', 'Worldwide'],
    component: DeniedDomainCombinationsComponent,
    motif: 'orange'
  },
  {
    name: "Denied Email Combinations",
    text: "Risky Email Address Combinations",
    description: "When more than one email address specified in a customer-defined group is included in an email's recipients.",
    tags: ['Risky Recipients', 'Email Address', 'Worldwide'],
    component: DeniedEmailCombinationsComponent,
    motif: 'orange'
  },
  {
    name: "Allowed Domain Combinations",
    text: "Safe Domain Name Combinations",
    description: "When there is not at least one email recipient represented in an email's recipients from each domain name specified in a customer-defined group.",
    tags: ['Safe Recipients', 'Email Address', 'Worldwide'],
    component: AllowedDomainCombinationsComponent,
    motif: 'lime'
  },
  {
    name: "Allowed Email Combinations",
    text: "Safe Email Address Combinations",
    description: "When there is one or more email addresses specified in a customer-defined group is missing from an email's recipients.",
    tags: ['Safe Recipients', 'Domain Name', 'Worldwide'],
    component: AllowedEmailCombinationsComponent,
    motif: 'lime'
  },
  {
    name: "DLP Text Matching",
    text: "Phrase Match Data Loss Prevention (DLP)",
    description: "When customer-defined text phrases (i.e. words, phrases, strings, numbers, etc.) are detected in the email subject, body, and/or attachment filename.",
    tags: ["DLP", "Subject", "Body", "Attachment Filename", "Worldwide"],
    component: DLPTextMatchingComponent,
    motif: 'purple'
  },
  {
    name: "DLP Regex Matching",
    text: "RegEx Match Data Loss Prevention (DLP)",
    description: "When customer-defined regular expressions (RegEx) result in a match in the email subject, body, and/or attachment filename.",
    tags: ["DLP", "Subject", "Body", "Attachment Filename", "Worldwide"],
    component: DLPRegexMatchingComponent,
    motif: 'purple'
  },
  // {
  //   name: "Compliant Email Combinations",
  //   component: CompliantEmailCombinationsComponent,
  // },
];

// click handlers

const handleCloseClick = () => {
  console.log('executing handleCloseClick');
  if(RulesStore.isActiveRuleModified){
    showConfirmationModal.value = true;
  }else{
    // If this is an EDIT slide over type, make sure to check if there are any changes
    console.log("reviewing contents....")
    closeEverything();
  }
}

const handleSaveAndEnableClick = () => {
  console.log('executing handleSaveAndEnableClick: ');

  let _activeRule = RulesStore.activeRule;
  _activeRule.setStatus(STATUS.STATUS_ENABLED);
  RulesStore.setActiveRule(_activeRule);

  console.log("SAVING RULE: ", RulesStore.activeRule)
  console.log("SAVING RULE: ", RulesStore.activeRule.toObject())

  // set to wait
  // save to backend
  // close everything
  proceedToSave();
}

const handleSaveClick = () => {
  console.log('executing handleSaveClick');
  console.log("SAVING RULE: ", RulesStore.activeRule)
  console.log("SAVING RULE: ", RulesStore.activeRule.toObject())
  // set to wait
  // save to backend
  // close everything
  proceedToSave();

}

const proceedToSave = async () => {

  SessionStore.setToWaitingState();

  try {  
    
    if(RulesStore.slideOverType == 'create'){
      console.log("Proceeding to createa new rule...");

      /**
       * Do some cleanup here with the list.
       * If there are any empty arrays, remove them.
       */

      await RulesStore.pushCreateRule(SessionStore.traceId);
      // await RulesStore.pushListRule(SessionStore.traceId); // bug fix 05-19-2023. Please groom.

      closeEverything();

      // @TODO: Must show a toast

    }else if(RulesStore.slideOverType == 'edit'){
      console.log("Proceeding to update existing rule...");

      await RulesStore.pushUpdateRule(SessionStore.traceId);
      // await RulesStore.pushListRule(SessionStore.traceId); // bug fix 05-19-2023. Please groom.

      closeEverything();

      // @TODO: Must show a toast

    }

    SessionStore.setToReadyState();
  } catch (err) {
    closeEverything();
    setTimeout(() => {
      SessionStore.setToErrorState();
      SessionStore.setError({
        message: err.message,
        code: err.code,
      });
    }, 800);
  }
}

/**
 * We will find the non-undefined attribute name
 * of the implementation.
 */
 const getImplementation = computed(() => {
  var _implementation = null;
  IMPLEMENTATIONS_COMPONENT_MAP.forEach((imp, index) => {
    let attributeName = imp.name.replace(/\s/g, "").toLowerCase();
    if (RulesStore.activeRule.toObject()[attributeName]) {
      _implementation = index;
    }
  });
  return _implementation;
})

const getImplementationUiData = computed(() => {
  var _implementation = null;
  IMPLEMENTATIONS_COMPONENT_MAP.forEach((imp, index) => {
    let attributeName = imp.name.replace(/\s/g, "").toLowerCase();
    if (RulesStore.activeRule.toObject()[attributeName]) {
      _implementation = imp;
    }
  });
  return _implementation;
})

const handleTypeChangeClick = () => {

  if(RulesStore.isActiveRuleModified){
    showConfirmationToChangeTypeModal.value = true;
  }else{  
    RulesStore.unsetActiveRule();
    RulesStore.resetActiveRuleAsModified();
    isRuleTypeInfoToggled.value = false;
  }
}

const proceedWithTypeChange = () => {
  RulesStore.unsetActiveRule();
  RulesStore.resetActiveRuleAsModified();
  isRuleTypeInfoToggled.value = false;
  showConfirmationToChangeTypeModal.value = false;
}

// ui actions 

const closeEverything = () => {
  
  console.log('closing everything');
  SessionStore.setToWaitingState();
  showConfirmationModal.value = false
  
  RulesStore.closeSlideOver();

  setTimeout(() => {
    RulesStore.unsetActiveRule();
    RulesStore.resetActiveRuleAsModified();
    console.log('unsetting active rule...');
    if(SessionStore.state != STATE.ERROR ){
      SessionStore.setToReadyState();
    }
    
  }, 1000);

}

</script>