<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-40"
  >
    <div class="flex w-full flex-col space-y-4 items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="(SessionStore.state == STATE.ERROR) ? true : false"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-40"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-semibold text-red-600">
                  An Error Occurred
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  {{ `Error code [${SessionStore.error.code} - ${enumToString(GRPC_ERROR, SessionStore.error.code)}], with the following message: ${SessionStore.error.message}` }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  @click="closeNotification()"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>

                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
  
<script setup>
import { STATE } from "../data/enums";
import { enumToString, GRPC_ERROR } from "@/modules/v2/utilities";
import { useV3SessionStore } from "../store/sessionStore.js";
const SessionStore = useV3SessionStore();

const closeNotification = () => {
  SessionStore.setToReadyState();
}
</script>