<template>
  <div class="flex space-x-1 items-center mb-2">
    <h3 class="prv-h3">
      {{ title }}
    </h3>
    <InfoIcon @click="isInfoSettingsToggled = !isInfoSettingsToggled" class="w-4 h-4 text-gray-500 cursor-pointer" />
  </div>

  <div>
    <div
      v-if="isInfoSettingsToggled"
      class="text-sm font-normal text-gray-600 w-full bg-gray-200 p-5 rounded-md my-2 mb-4 space-y-4"
    >

    <p v-for="(parag, index) in info" :key="index">
      {{ parag }}
    </p>
      
  </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import InfoIcon from "../../icons/InfoIcon.vue";
const props = defineProps(['title', 'info']);
const isInfoSettingsToggled = ref(false)
</script>