lea<template>
  <div class="space-y-8">
    <!-- Name Section -->
    <section id="rule-name">
      <TitleSection title="Rule Name" />
      <RuleInput v-model="name" :isValid="isValidName" />
    </section>
    <!-- Match List -->
    <section id="rule-match-list">
      <TitleWithInfoSection title="Safe Email Address Combinations" :info="UIS_COMBINATIONS_LIST_INFO" />
      <EmailCombinationsList :emailCombinationsList="defaultEmailCombinationsList" :groupNameList="defaultGroupNameList" @action="combinationsListWatcher" @actionGroupName="groupNamesWatcher"/>
    </section>
    <!-- Settings Section -->
    <section id="rule-settings">
      <TitleWithInfoSection title="Settings" :info="UIS_SETTINGS_INFO" />
      <div class="space-y-0.5">
        <RuleToggle v-model="status" label="Rule" />
        <RuleToggle v-model="logging" label="Logging" />
      </div>
    </section>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import RuleInput from "../slideover/RuleInput.vue";
import TitleSection from "../slideover/TitleSection.vue";
import TitleWithInfoSection from "../slideover/TitleWithInfoSection.vue";
import RuleToggle from "../slideover/RuleToggle.vue";
import EmailCombinationsList from "../slideover/EmailCombinationsList.vue";
import { Email, EmailList, CombinationEmail } from "@preava/preava-prevent-api-grpc-web-js/types_pb";

import {
  Logging as LOGGING,
  Status as STATUS,
} from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

import { useV3RulesStore } from "../../store/rulesStore.js";
const RulesStore = useV3RulesStore();

const statusToBool = (status) => {
  if (status == STATUS.STATUS_ENABLED) return true;
  if (status == STATUS.STATUS_DISABLED) return false;
  return null;
};

const loggingToBool = (logging) => {
  if (logging == LOGGING.LOGGING_ENABLED) return true;
  if (logging == LOGGING.LOGGING_DISABLED) return false;
  return null;
};

// ui - populate initial values
const name = ref(RulesStore.activeRule.getName());
const status = ref(statusToBool(RulesStore.activeRule.getStatus()));
const logging = ref(loggingToBool(RulesStore.activeRule.getLogging()));
const defaultEmailCombinationsList = ref([]);
const defaultGroupNameList = ref([]);


// set the default locations to all locations
if(RulesStore.slideOverType == 'create'){
  // add 1 default list
  let emailList = new Array();
  defaultEmailCombinationsList.value.push(emailList);

  // add 1 default group name
  defaultGroupNameList.value.push('Group 1');


}else if(RulesStore.slideOverType == 'edit'){
  // set current rule data locations
  const _activeRule = RulesStore.activeRule;
  const _allowedEmailCombinations = _activeRule.getAllowedemailcombinations();

  const _emailCombinations = _allowedEmailCombinations.getEmailCombinationsList();
  let _emailList = [];
  let _groupNames = [];
  _emailCombinations.forEach( emailCombination => {

    let _emails = [];
    let emailsList = emailCombination.getEmails();

    emailsList.getValuesList().forEach( email => {
      _emails.push(email.getValue())
    });
    _emailList.push(_emails);
    _groupNames.push(emailCombination.getName());
  });
  console.log(_emailList);
  
  defaultEmailCombinationsList.value = _emailList;
  defaultGroupNameList.value = _groupNames;

}

// watchers to update
watch(name, (newValue) => {
  RulesStore.flagActiveRuleAsModified();
  if (isValidName(newValue)) {
    let _activeRule = RulesStore.activeRule;
    _activeRule.setName(newValue);
    RulesStore.setActiveRule(_activeRule);
  }
});

watch(status, (newValue) => {
  RulesStore.flagActiveRuleAsModified();
  let _activeRule = RulesStore.activeRule;
  _activeRule.setStatus(
    newValue ? STATUS.STATUS_ENABLED : STATUS.STATUS_DISABLED
  );
  RulesStore.setActiveRule(_activeRule);
});

const combinationsListWatcher = (combinationsList) => {

  console.log('combinationsListWatcher fired');

  RulesStore.flagActiveRuleAsModified();
  
  const _activeRule = RulesStore.activeRule;
  const _allowedEmailCombinations = _activeRule.getAllowedemailcombinations();
  _allowedEmailCombinations.clearEmailCombinationsList();
  // console.log(_allowedEmailCombinations)

  combinationsList.forEach( (combination, index) => {

    let _emails = []
    combination.forEach( email => {
      let _email = new Email();
      _email.setValue(email);
      _emails.push(_email)
    });

    let _emailList = new EmailList();
    _emailList.setValuesList(_emails);

    let combinationEmail = new CombinationEmail();

    combinationEmail.setEmails(_emailList);
    combinationEmail.setName(defaultGroupNameList.value[index]);

    _allowedEmailCombinations.addEmailCombinations(combinationEmail)
  });

  _activeRule.setAllowedemailcombinations(_allowedEmailCombinations);
  RulesStore.setActiveRule(_activeRule);
  console.log('RulesStore.activeRule', RulesStore.activeRule.toObject())
}

const groupNamesWatcher = (groupNames) => {

console.log('groupNamesWatcher fired');

RulesStore.flagActiveRuleAsModified();

const _activeRule = RulesStore.activeRule;
const _allowedEmailCombinations = _activeRule.getAllowedemailcombinations();
const _combinationsList = _allowedEmailCombinations.getEmailCombinationsList();

_combinationsList.forEach( (emailCombination, index) => {
  emailCombination.setName(groupNames[index])
})

console.log('RulesStore.activeRule', RulesStore.activeRule.toObject())
}



// ui strings
const UIS_COMBINATIONS_LIST_INFO = ["Start by adding safe email address combinations to the first group. Add additional groups to define additional safe email address combinations (e.g. separate projects, cases, etc.)."];
const UIS_SETTINGS_INFO = [
  "You can enable and disable rules with the first toggle switch. Rules are disabled by default when newly created.", 
  "Logging of end-user data can be enabled and disabled with the second toggle switch. Enabled Rules with logging enabled will collect data that is available in the Logs tab of the Admin Dashboard."
]

// validation
const isValidName = (item) => {
  if (
    item.trim() == "" ||
    item == undefined ||
    item == null
  ) {
    return {
      isValid: false,
      errorMessage: "You entered an empty string",
    };
  } else if (item.length < 4) {
    return {
      isValid: false,
      errorMessage: "Please input more than 3 characters.",
    };
  } else if (RulesStore.ruleNames.includes(item) && item != RulesStore.activeRule.getName()) {
    return {
      isValid: false,
      errorMessage: "This name already exists.",
    };
  } else {
    return {
      isValid: true,
    };
  }
};

</script>