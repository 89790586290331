<template>
  <div class="col-span-full mt-2">
    <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600">
      <input
        v-model="formInput"
        @blur="addItem"
        @input="onUpdate"
        type="text"
        name="rulename"
        id="rulename"
        :class="[
          errorMessage && formInput.length != 0
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
            : 'text-gray-900 placeholder-gray-300 focus:border-gray-500 focus:ring-gray-500 border-gray-300',
          'rounded-md block w-full pr-10 focus:outline-none sm:text-sm bg-white px-3 py-2',
        ]"
      />
    </div>
    <!-- Error Section -->
    <section
      v-if="errorMessage && formInput.length != 0"
      class="mt-1 text-sm text-red-600"
    >
      {{ errorMessage }}
    </section>
  </div>
</template>
<script setup>
/**
 * Declare dependencies
 */
import { ref } from "vue";

/**
 * Define props and emits
 */
const props = defineProps(["modelValue", "isValid", "title", "existingNames"]);
const emits = defineEmits(["update:modelValue"]);

/**
 * UI variables
 */
const formInput = ref(props.modelValue); // initialize based on passed value
const errorMessage = ref("");

/**
 * Here we add an item after it's validated.
 */
const addItem = () => {
  /**
   * Here we validate an input using the passed
   * function. This can be a function to validate
   * a simple string, domain, email or regex
   */
  let result = props.isValid(formInput.value);

  /**
   * If result is invalid, display the error
   * and terminate the function via "Gating".
   */
  if (!result.isValid) {
    errorMessage.value = result.errorMessage;
    emits("update:modelValue", formInput.value);
    return;
  }

  /**
   * Otherwise, proceed with the item.
   * in here we add the item to the temporary items
   * array and the parent items array witll be updated
   */
  emits("update:modelValue", formInput.value);

  /**
   * Clear out UI
   */
  errorMessage.value = "";
};

/**
 * This fires everytime the user updates the input.
 * Only do light checks with this to not spend too much
 * computation power.
 */
const onUpdate = () => {
  /**
   * Here, we only check if the input is already empty
   * and remove any error message if it's the case.
   */
  if (formInput.value.length == 0) {
    errorMessage.value = "";
  }
};
</script>