<template>
  <div class="flex space-x-1 items-center mb-2">
    <h3 class="prv-h3">
      {{ title }}
    </h3>
  </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps(["title"]);
const isInfoSettingsToggled = ref(false);
</script>
<style scoped>
  @import './slideover.css';
</style>