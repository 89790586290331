<template>
  <section id="rule-menu" class="overflow-show">
    <div class="overflow-x-scroll grid grid-cols-1 gap-4 py-2">
      <div
        v-for="(menuItem, index) in IMPLEMENTATIONS"
        :key="`menu-${index}`"
        class="w-full h-auto text-sm flex flex-col justify-between py-5 px-8 rounded-md hover:shadow-sm transition-all"
        :class="[
          (menuItem.motif == 'purple') ? 'bg-purple-100 text-purple-700 ' : '',
          (menuItem.motif == 'lime') ? 'bg-lime-100 text-lime-700 ' : '',
          (menuItem.motif == 'orange') ? 'bg-orange-100 text-orange-700 ' : '',
        ]"
      >
      <div>
        
        <div class="float-right w-full font-bold mt-2 text-md">
          {{ menuItem.text }}
        </div>
        <div class="float-right w-full mt-1">
          {{ menuItem.description }}
        </div>
      </div>
        
        <div class="mt-2">
          <div class="w-full">
            <span v-for="tag in menuItem.tags" :key="tag" 
              class="inline-flex items-center rounded-md px-2 py-0.5 text-xs ring-1 ring-inset mr-2 my-1"
              :class="[
                (menuItem.motif == 'purple') ? 'ring-purple-400 text-purple-700 ' : '',
                (menuItem.motif == 'lime') ? 'ring-lime-600 text-lime-700 ' : '',
                (menuItem.motif == 'orange') ? 'ring-orange-400 text-orange-700 ' : '',
              ]">
              {{ tag }}
            </span>
          </div>
        </div>
        <button @click="openSlideOver(index)" type="button" class="w-auto flex items-center justify-center my-3 disabled:opacity-50 disabled:cursor-default rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 mr-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
            <span>Create Rule</span>
          </button>
        <!-- <div 
          class="-ml-1 rounded-full flex justify-center items-center py-2 space-x-1 mt-3"
          :class="[
            (menuItem.motif == 'purple') ? 'bg-purple-600 text-purple-50' : '',
            (menuItem.motif == 'lime') ? 'bg-lime-600 text-lime-50' : '',
            (menuItem.motif == 'orange') ? 'bg-orange-600 text-orange-50' : '',
          ]"
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span>Create Rule</span>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script setup>

import { Logging as LOGGING, Status as STATUS } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

import {
  RuleData, 
  DLPTextMatching,
  DLPRegexMatching,
  AllowedEmailCombinations,
  AllowedDomainCombinations,
  DeniedEmailCombinations,
  DeniedDomainCombinations,
  CompliantEmailCombinations,
} from "@preava/preava-prevent-api-grpc-web-js/public_pb";

import { useV3RulesStore } from "../store/rulesStore.js";
const RulesStore = useV3RulesStore();

// const emits = defineEmits(["action"]);

/**
 * Map out the implementations with its data structure.
 * Whenever someone wants to create a rule, the implementation
 * is set to the selected rule data in the store.
 * 
 * That implementation is then read by the slide over component
 * via the selected rule data to generate the 
 * implementation subcomponent.
 * 
 */
const IMPLEMENTATIONS = [
  {
    text: "Risky Domain Name Combinations",
    description: "When more than one domain name specified in a customer-defined group is represented in an email's recipients.",
    tags: ['Risky Recipients', 'Domain Name'],
    name: "Denied Domain Combinations",
    implementation: new DeniedDomainCombinations(),
    motif: 'orange'
  },
  {
    text: "Risky Email Address Combinations",
    name: "Denied Email Combinations",
    description: "When more than one email address specified in a customer-defined group is included in an email's recipients.",
    tags: ['Risky Recipients', 'Email Address'],
    implementation: new DeniedEmailCombinations(),
    motif: 'orange'
  },
  {
    text: "Safe Domain Name Combinations",
    description: "When there is not at least one email recipient represented in an email's recipients from each domain name specified in a customer-defined group.",
    tags: ['Safe Recipients', 'Email Address'],
    name: "Allowed Domain Combinations",
    implementation: new AllowedDomainCombinations(),
    motif: 'lime'
  },
  {
    text: "Safe Email Address Combinations",
    description: "When there is one or more email addresses specified in a customer-defined group is missing from an email's recipients.",
    tags: ['Safe Recipients', 'Domain Name'],
    name: "Allowed Email Combinations",
    implementation: new AllowedEmailCombinations(),
    motif: 'lime'
  },
  
  // {
  //   text: "FIXME: Recipients: compliant email combinations",
  //   name: "Compliant Email Combinations",
  //   implementation: new CompliantEmailCombinations(),
  //   motif: 'orange'
  // },
  {
    text: "Phrase Match Data Loss Prevention (DLP)",
    description: "When customer-defined text phrases (i.e. words, phrases, strings, numbers, etc.) are detected in the email subject, body, and/or attachment filename.",
    tags: ["DLP", "Subject", "Body", "Attachment Filename"],
    name: "DLP Text Matching",
    implementation: new DLPTextMatching(),
    motif: 'purple'
  },
  {
    text: "RegEx Match Data Loss Prevention (DLP)",
    description: "When customer-defined regular expressions (RegEx) result in a match in the email subject, body, and/or attachment filename.",
    tags: ["DLP", "Subject", "Body", "Attachment Filename"],
    name: "DLP Regex Matching",
    implementation: new DLPRegexMatching(),
    motif: 'purple'
  },
];

const getPriority = () => {
  let _prio = null;
  if(RulesStore.highestPriority >= 200000 && RulesStore.highestPriority <= 400000){
    _prio = RulesStore.highestPriority+1;
  }else{
    _prio = 200000;
  }
  return _prio;
}
  

/**
 * Whenever the Slide Over is opened,
 * we need to set the selected rule data's
 * implementation in the store
 */
const openSlideOver = (index) => {

  
  /**
   * Create a new Rule Data.
   * Get the current implementation function name and
   * then set the new Rule Data's implementation.
   */
  let _ruleData = new RuleData();

  // set default values
  // _ruleData.setName(`Rule ${RulesStore.ruleNames.length+1}: ${IMPLEMENTATIONS[index].name}`);
  _ruleData.setName(`Rule ${RulesStore.ruleNames.length+1}`);
  _ruleData.setLogging(LOGGING.LOGGING_ENABLED);
  _ruleData.setStatus(STATUS.STATUS_DISABLED);
  _ruleData.setPriority(getPriority());

  let funcName = convertImplementationNameToFuncName(IMPLEMENTATIONS[index].name)
  _ruleData[`set${funcName}`](IMPLEMENTATIONS[index].implementation);
  
  console.log(`Creating a new Rule Data with [${IMPLEMENTATIONS[index].name}] implementation: `, _ruleData)

  // show the rule slideover
  RulesStore.setActiveRule(_ruleData);

  window.scrollTo(0,0);
  // RulesStore.setSlideOverType('create');
  // RulesStore.openSlideOver();
  
};

/**
 * Given that the implementation name structure is "Implementation Name", 
 * we manipulate the string to derive the function name
 * where we can set and get the implementation
 */
const convertImplementationNameToFuncName = (name) => {
  let _name = name.replace(/\s/g, '');
  _name = _name.charAt(0).toUpperCase() + _name.slice(1).toLowerCase();
  return _name 
}

</script>