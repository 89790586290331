import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { listRule, createRule, deleteRule, updateRule } from "../adapter/ruleAdapter.js";
import { Status as STATUS } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

const PREFIX = '🍍STORE';

const IMPLEMENTATIONS = [
    'AllowedEmailCombinations',
    'AllowedDomainCombinations',
    'DeniedEmailCombinations',
    'DeniedDomainCombinations',
    'CompliantEmailCombinations',
    'DLPTextMatching',
    'DLPRegexMatching',
]
const IMPLEMENTATION_NAME_MAPPING = new Map([
    ["allowedemailcombinations", "Allowed Email Combinations"],
    ["alloweddomaincombinations", "Allowed Domain Combinations"],
    ["deniedemailcombinations", "Denied Email Combinations"],
    ["denieddomaincombinations", "Denied Domain Combinations"],
    ["compliantemailcombinations", "Compliant Email Combinations"],
    ["dlptextmatching", "DLP Text Matching"],
    ["dlpregexmatching", "DLP RegEx Matching"],
]);

const IMPLEMENTATION_COLOR_MAPPING = new Map([
    ["allowedemailcombinations", "lime"],
    ["alloweddomaincombinations", "lime"],
    ["deniedemailcombinations", "orange"],
    ["denieddomaincombinations", "orange"],
    ["compliantemailcombinations", "orange"],
    ["dlptextmatching", "purple"],
    ["dlpregexmatching", "purple"],
]);

export const useV3RulesStore = defineStore('V3RulesStore', () => {

    const rules = ref([]);

    const rulesForUi = ref([]);
    const isSlideOverOpen = ref(false);
    const slideOverType = ref(null);

    const activeRule = ref(null);
    const activeRulePayload = ref(null);
    const isActiveRuleModified = ref(false);

    const isReadyToProceed = (_rule) => {
        let rule = _rule.toObject()
        let implementation = '';
        IMPLEMENTATIONS.forEach( imp => {
            if(rule[imp.toLowerCase()]){
                // implementation = IMPLEMENTATION_NAME_MAPPING.get(imp.toLowerCase());
                implementation = imp.toLowerCase();
            }
        });

        if(implementation == "allowedemailcombinations" || implementation == "deniedemailcombinations"){
            // if there are no combinations list at all
            if(rule[implementation].emailCombinationsList.length == 0){
                // check if there are values in values list
                return true
            }
            // if there is only one combinations list but there are no emails inside (i.e. they set the name but never added emails)
            if(rule[implementation].emailCombinationsList.length == 1 && rule[implementation].emailCombinationsList[0].emails.valuesList.length == 0){
                // check if there are values in values list
                return true
            }
            // if nothing is empty, return false
            return false
        }

        else if(implementation == "alloweddomaincombinations" || implementation == "denieddomaincombinations"){
            // if there are no combinations list at all
            if(rule[implementation].domainCombinationsList.length == 0){
                // check if there are values in values list
                return true
            }
            // if there is only one combinations list but there are no domains inside (i.e. they set the name but never added domains)
            if(rule[implementation].domainCombinationsList.length == 1 && rule[implementation].domainCombinationsList[0].domains.valuesList.length == 0){
                // check if there are values in values list
                return true
            }
            // if nothing is empty, return false
            return false
        }

        else if(implementation == "dlptextmatching") {
            // check the matches list is empty
            if(rule[implementation].matchesList.length == 0){
                return true
            }else{
                return false
            }
        }
        else if(implementation == "dlpregexmatching") {
            // check the regex list is empty
            if(rule[implementation].regexesList.length == 0){
                return true
            }else{
                return false
            }
        }

    }

    // getters

    const activeRuleObj = computed( () => (activeRule.value) ? activeRule.value.toObject() : null)

    const rulesObj = computed( () => {
        let _res = [];
        rules.value.forEach( rule => {
            _res.push(rule.toObject());
        });
        return _res;
    });

    const ruleNames = computed( () => {
        let _res = [];
        rules.value.forEach( rule => {
            _res.push(rule.getData().getName());
        });
        return _res;
    });

    const highestPriority = computed( () => {
        let _prio = 0;
        rules.value.forEach( rule => {
            let _val = rule.getData().getPriority();
            if(_val > _prio){
                _prio = _val
            }
        });
        return _prio;
    });


    // actions 
    
    const getRuleImplementation = (objectId) => {
        let _rule = rulesObj.value.find( e => e.objectId.value == objectId )
        let _implementation = '';
        IMPLEMENTATIONS.forEach( imp => {
            if(_rule.data[imp.toLowerCase()]){
                _implementation = imp.toLowerCase();
            }
        });
        return _implementation;
    }

    const internalGetRuleImp = (rule) => {
        let _rule = rule.toObject();
        let _implementation = '';
        IMPLEMENTATIONS.forEach( imp => {
            if(_rule.data[imp.toLowerCase()]){
                _implementation = IMPLEMENTATION_NAME_MAPPING.get(imp.toLowerCase());
            }
        });
        return _implementation;
    }

    const internalGetColorImp = (rule) => {
        let _rule = rule.toObject();
        let _color = '';
        IMPLEMENTATIONS.forEach( imp => {
            if(_rule.data[imp.toLowerCase()]){
                _color = IMPLEMENTATION_COLOR_MAPPING.get(imp.toLowerCase());
            }
        });
        return _color;
    }

    const pushListRule = (traceId) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(`${PREFIX}: Executing pushListRule with trace ID ${traceId}`);
                
                let listRuleRes = await listRule(traceId);
                console.log(`${PREFIX}: Backend returned the following response: `, listRuleRes);
                
                const sortedList = listRuleRes.sort((a, b) => a.getData().getPriority() - b.getData().getPriority());

                rules.value = sortedList;

                let _temp = [];
                sortedList.forEach( rule => {
                    _temp.push({
                        id: rule.getObjectId().getValue(), 
                        entry: rule.getData().getName(), 
                        priority: rule.getData().getPriority(), 
                        isEnabled: (rule.getData().getStatus() == STATUS.STATUS_ENABLED) ? true : false, 
                        status: rule.getData().getStatus(),
                        implementation: internalGetRuleImp(rule),
                        info: rule.getInfo().getName(),
                        color: internalGetColorImp(rule),
                        isSelected: false, 
                    })    
                });
                rulesForUi.value = _temp;


                resolve(true)
            } catch (err) {
                console.error(err)
                reject(err)
            }
        })
    }

    const pushCreateRule = (traceId) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(`${PREFIX}: Executing pushCreateRule with trace ID ${traceId}`);
            
                // @TODO: Clear this out once confirmed
                // let createRuleRes = await createRule(traceId, activeRule.value);
                // console.log(`${PREFIX}: Backend returned the following response: `, createRuleRes);

                // 05/19/2023 - list rule delay fix
                let createRuleRes = await createRule(traceId, activeRule.value);
                console.log(`${PREFIX}: Backend returned the following response: `, createRuleRes);

                // update the rules by adding the create rule results
                let _rules = rules.value;
                _rules.push(createRuleRes);
                
                const sortedList = _rules.sort((a, b) => a.getData().getPriority() - b.getData().getPriority());

                rules.value = sortedList;

                let _temp = [];
                sortedList.forEach( rule => {
                    _temp.push({
                        id: rule.getObjectId().getValue(), 
                        entry: rule.getData().getName(), 
                        priority: rule.getData().getPriority(), 
                        isEnabled: (rule.getData().getStatus() == STATUS.STATUS_ENABLED) ? true : false, 
                        status: rule.getData().getStatus(),
                        implementation: internalGetRuleImp(rule),
                        info: rule.getInfo().getName(),
                        color: internalGetColorImp(rule),
                        isSelected: false, 
                    })    
                });
                rulesForUi.value = _temp;
                /** end of list rule delay fix */
                
                console.log(createRuleRes);

                resolve(true)
            } catch (err) {
                console.error(err)
                reject(err)
            }
        })
    }

    const pushUpdateRule = (traceId) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(`${PREFIX}: Executing pushUpdateRule with trace ID ${traceId}`);
            
                let ruleObjectId = activeRulePayload.value.getObjectId();
                let ruleData = activeRule.value;
                
                console.log(ruleObjectId, ruleData)
                
                // @TODO: Clear this out once confirmed
                // let updateRuleRes = await updateRule(traceId, ruleObjectId, ruleData);
                // console.log(`${PREFIX}: Backend returned the following response: `, updateRuleRes);

                // 05/19/2023 - list rule delay fix
                let updateRuleRes = await updateRule(traceId, ruleObjectId, ruleData);
                console.log(`${PREFIX}: Backend returned the following response: `, updateRuleRes);

                // update the rules by adding the create rule results
                let _rules = rules.value;
                let index = _rules.findIndex( rule => rule.getObjectId() == ruleObjectId);
                _rules[index] = updateRuleRes;
                
                const sortedList = _rules.sort((a, b) => a.getData().getPriority() - b.getData().getPriority());

                rules.value = sortedList;

                let _temp = [];
                sortedList.forEach( rule => {
                    _temp.push({
                        id: rule.getObjectId().getValue(), 
                        entry: rule.getData().getName(), 
                        priority: rule.getData().getPriority(), 
                        isEnabled: (rule.getData().getStatus() == STATUS.STATUS_ENABLED) ? true : false, 
                        status: rule.getData().getStatus(),
                        implementation: internalGetRuleImp(rule),
                        info: rule.getInfo().getName(),
                        color: internalGetColorImp(rule),
                        isSelected: false, 
                    })    
                });
                rulesForUi.value = _temp;
                /** end of list rule delay fix */

                resolve(true)
            } catch (err) {
                console.error(err)
                reject(err)
            }
        })
    }

    const pushDeleteRule = (traceId, objectId) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(`${PREFIX}: Executing pushDeleteRule with trace ID ${traceId}`);
                let deleteRuleRes = await deleteRule(traceId, objectId);
                console.log(`${PREFIX}: Backend returned the following response: `, deleteRuleRes);
                resolve(true);
            } catch (err) {
                console.error(err)
                reject(err)
            }
        })
    }

    // UI Functions
    const openSlideOver = () => isSlideOverOpen.value = true;
    const closeSlideOver = () => isSlideOverOpen.value = false;
    const setSlideOverType = (type) => slideOverType.value = type;
    const setActiveRule = (rule) => activeRule.value = rule;
    const unsetActiveRule = () => activeRule.value = null;
    const setActiveRulePayload = (rulePayload) => activeRulePayload.value = rulePayload;
    const flagActiveRuleAsModified = () => { isActiveRuleModified.value = true }
    const resetActiveRuleAsModified = () => { isActiveRuleModified.value = false }

    return { 
        // rule data
        rules, rulesObj, rulesForUi, ruleNames, highestPriority,
        pushCreateRule, pushListRule, pushDeleteRule, pushUpdateRule,
        getRuleImplementation,

        // UI
        isSlideOverOpen, slideOverType, openSlideOver, closeSlideOver, setSlideOverType,
        activeRule, activeRuleObj, setActiveRule, unsetActiveRule,
        activeRulePayload, setActiveRulePayload,
        isActiveRuleModified, flagActiveRuleAsModified, resetActiveRuleAsModified,
        isReadyToProceed, internalGetRuleImp

        
    }
})