lea<template>
  <div class="space-y-8">
    <!-- Name Section -->
    <section id="rule-name">
      <TitleSection title="Rule Name" />
      <RuleInput v-model="name" :isValid="isValidName" />
    </section>
    <!-- Match List -->
    <section id="rule-match-list">
      <TitleWithInfoSection title="Domain Combinations" :info="UIS_COMBINATIONS_LIST_INFO" />
      <DomainCombinationsList :domainCombinationsList="defaultDomainCombinationsList" :groupNameList="defaultGroupNameList" @action="combinationsListWatcher" @actionGroupName="groupNamesWatcher"/>
    </section>
    <!-- Settings Section -->
    <section id="rule-settings">
      <TitleWithInfoSection title="Settings" :info="UIS_SETTINGS_INFO" />
      <div class="space-y-0.5">
        <RuleToggle v-model="status" label="Rule" />
        <RuleToggle v-model="logging" label="Logging" />
      </div>
    </section>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import RuleInput from "../slideover/RuleInput.vue";
import TitleSection from "../slideover/TitleSection.vue";
import TitleWithInfoSection from "../slideover/TitleWithInfoSection.vue";
import RuleToggle from "../slideover/RuleToggle.vue";
import DomainCombinationsList from "../slideover/DomainCombinationsList.vue";
import { Domain, DomainList, CombinationDomain } from "@preava/preava-prevent-api-grpc-web-js/types_pb";

import {
  Logging as LOGGING,
  Status as STATUS,
} from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

import { useV3RulesStore } from "../../store/rulesStore.js";
const RulesStore = useV3RulesStore();

const statusToBool = (status) => {
  if (status == STATUS.STATUS_ENABLED) return true;
  if (status == STATUS.STATUS_DISABLED) return false;
  return null;
};

const loggingToBool = (logging) => {
  if (logging == LOGGING.LOGGING_ENABLED) return true;
  if (logging == LOGGING.LOGGING_DISABLED) return false;
  return null;
};

// ui - populate initial values
const name = ref(RulesStore.activeRule.getName());
const status = ref(statusToBool(RulesStore.activeRule.getStatus()));
const logging = ref(loggingToBool(RulesStore.activeRule.getLogging()));
const defaultDomainCombinationsList = ref([]);
const defaultGroupNameList = ref([]);


// set the default locations to all locations
if(RulesStore.slideOverType == 'create'){
  // add 1 default list
  let domainList = new Array();
  defaultDomainCombinationsList.value.push(domainList);

  // add 1 default group name
  defaultGroupNameList.value.push('Group 1');

}else if(RulesStore.slideOverType == 'edit'){
  // set current rule data locations
  const _activeRule = RulesStore.activeRule;
  const _deniedDomainCombinations = _activeRule.getDenieddomaincombinations();

  const _domainCombinations = _deniedDomainCombinations.getDomainCombinationsList();
  let _domainList = [];
  let _groupNames = [];
  _domainCombinations.forEach( domainCombination => {

    let _domains = [];
    let domainsList = domainCombination.getDomains();

    domainsList.getValuesList().forEach( domain => {
      _domains.push(domain.getValue())
    });
    _domainList.push(_domains);
    _groupNames.push(domainCombination.getName());
  });
  console.log(_domainList);
  
  defaultDomainCombinationsList.value = _domainList;
  defaultGroupNameList.value = _groupNames;

}

// watchers to update
watch(name, (newValue) => {
  RulesStore.flagActiveRuleAsModified();
  if (isValidName(newValue)) {
    let _activeRule = RulesStore.activeRule;
    _activeRule.setName(newValue);
    RulesStore.setActiveRule(_activeRule);
  }
});

watch(status, (newValue) => {
  RulesStore.flagActiveRuleAsModified();
  let _activeRule = RulesStore.activeRule;
  _activeRule.setStatus(
    newValue ? STATUS.STATUS_ENABLED : STATUS.STATUS_DISABLED
  );
  RulesStore.setActiveRule(_activeRule);
});

const combinationsListWatcher = (combinationsList) => {

  console.log('combinationsListWatcher fired');

  RulesStore.flagActiveRuleAsModified();
  
  const _activeRule = RulesStore.activeRule;
  const _deniedDomainCombinations = _activeRule.getDenieddomaincombinations();
  _deniedDomainCombinations.clearDomainCombinationsList();
  // console.log(_deniedDomainCombinations)

  combinationsList.forEach( (combination, index) => {

    let _domains = []
    combination.forEach( domain => {
      let _domain = new Domain();
      _domain.setValue(domain);
      _domains.push(_domain)
    });

    let _domainList = new DomainList();
    _domainList.setValuesList(_domains);
    
    let combinationDomain = new CombinationDomain();
    
    combinationDomain.setDomains(_domainList);
    combinationDomain.setName(defaultGroupNameList.value[index]);

    _deniedDomainCombinations.addDomainCombinations(combinationDomain)
  });

  _activeRule.setDenieddomaincombinations(_deniedDomainCombinations);
  RulesStore.setActiveRule(_activeRule);
  console.log('RulesStore.activeRule', RulesStore.activeRule.toObject())
}


const groupNamesWatcher = (groupNames) => {

  console.log('groupNamesWatcher fired');

  RulesStore.flagActiveRuleAsModified();

  const _activeRule = RulesStore.activeRule;
  const _deniedDomainCombinations = _activeRule.getDenieddomaincombinations();
  const _combinationsList = _deniedDomainCombinations.getDomainCombinationsList();
  
  _combinationsList.forEach( (domainCombination, index) => {
    domainCombination.setName(groupNames[index])
  })

  console.log('RulesStore.activeRule', RulesStore.activeRule.toObject())
}

// ui strings
const UIS_SETTINGS_INFO = [
  "You can enable and disable rules with the first toggle switch. Rules are disabled by default when newly created.", 
  "Logging of end-user data can be enabled and disabled with the second toggle switch. Enabled Rules with logging enabled will collect data that is available in the Logs tab of the Admin Dashboard."
]
const UIS_COMBINATIONS_LIST_INFO = ["Start by adding risky domain name combinations to the first group. Add additional groups to define additional risky domain name combinations (e.g. separate projects, cases, etc.)."];

// validation
const isValidName = (item) => {
  if (
    item.trim() == "" ||
    item == undefined ||
    item == null
  ) {
    return {
      isValid: false,
      errorMessage: "You entered an empty string",
    };
  } else if (item.length < 4) {
    return {
      isValid: false,
      errorMessage: "Please input more than 3 characters.",
    };
  } else if (RulesStore.ruleNames.includes(item) && item != RulesStore.activeRule.getName()) {
    return {
      isValid: false,
      errorMessage: "This name already exists.",
    };
  } else {
    return {
      isValid: true,
    };
  }
};

</script>