import { defineStore } from "pinia";
import { ref } from "vue";
import { v4 } from "uuid";
import { STATE } from "../data/enums";

export const useV3SessionStore = defineStore('V3SessionStore', () => {

    const traceId = ref('');
    const state = ref({});
    const error = ref({});

    const setTraceId = () => { traceId.value = v4() };
    const setError = (err) => { error.value = err };
    const setToInitializeState = () => state.value = STATE.INITIALIZING;
    const setToWaitingState = () => state.value = STATE.WAITING;
    const setToReadyState = () => state.value = STATE.READY;
    const setToErrorState = () => state.value = STATE.ERROR;
    return { 
        state, error, traceId, 
        setTraceId, setError,
        setToInitializeState, setToWaitingState, setToReadyState, setToErrorState
    }
})