import { RulesClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";
import { CreateRuleRequest, UpdateRuleRequest, RuleData } from "@preava/preava-prevent-api-grpc-web-js/public_pb";
import { ObjectId } from "@preava/preava-prevent-api-grpc-web-js/ids_pb";
import { Empty } from "@preava/preava-prevent-api-grpc-web-js/messages_pb";
import { logout, getMetadata, getEndpoint, enumToString, GRPC_ERROR } from "@/modules/v2/utilities";

const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}

const PREFIX = '🔮ADAPTER';
// const ENDPOINT = getEndpoint();
const ENDPOINT = getApi(location.host);

export const listRule = async (traceId) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Retreiving all rules from the backend...`);

    const metadata = await getMetadata(null, traceId); // null=default request type

    const request = new Empty();
    
    const gateway = new RulesClient(ENDPOINT, null, null);
    const stream = gateway.list(request, metadata);

    var _rules = [];
    console.log(`[${PREFIX}]: The rule client stream is now starting.`);
    
    /**
     * Stream received data object
     */
    stream.on("data", function (res) {
      console.log(`[${PREFIX}]: A rule client stream response was received from the backend:`, res);
      _rules.push(res)
    });

    /**
     * Stream received an error object
     */
    stream.on("error", function (err) {
      let err_data = null;
      console.error(`[${PREFIX}]: A rule client stream error was returned:`, err);
      stream.cancel();
      if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
        // If user is unauthenticated, always logout. 
        // Otherwise, return the error to the UI for display
        err_data = {
          type: 'support',
          message: err.message,
          details: err.details,
          code: err.code,
          status: enumToString(GRPC_ERROR, err.code),
          requestId: metadata['x-request-id'],
          correlationId: metadata['x-trace-id'],
        }
        logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
      } else {
        reject(err);
      }
    });

    /**
     * Stream has ended
     */
    stream.on("end", () => {
      console.log(`[${PREFIX}]: The rule client stream ended.`);
      stream.cancel();
      resolve(_rules)
    });

  }) // end of promise
}

export const createRule = async (traceId, ruleData) => {
  return new Promise(async (resolve, reject) => {

    const metadata = await getMetadata(null, traceId);

    const request = new CreateRuleRequest();
    request.setData(ruleData);
    
    const gateway = new RulesClient(ENDPOINT, null, null);
    
    gateway.create(request, metadata, (err, res) => {

      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Error occurred while creating new rule: `);
        console.error(err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        console.log(`[${PREFIX}]: Create rule was successful.`);
        resolve(res);
      }
    })

  }) // end of promise
}

export const updateRule = async (traceId, objectId, ruleData) => {
  return new Promise(async (resolve, reject) => {

    const metadata = await getMetadata(null, traceId);

    // const request = rulePayload;

    const request = new UpdateRuleRequest();
    request.setObjectId(objectId);
    request.setData(ruleData);
    
    const gateway = new RulesClient(ENDPOINT, null, null);
    
    gateway.update(request, metadata, (err, res) => {

      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Error occurred while updating rule: `);
        console.error(err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        console.log(`[${PREFIX}]: Update rule was successful.`);
        resolve(res);
      }
    })

  }) // end of promise
}

export const deleteRule = async (traceId, objectId) => {
  return new Promise(async (resolve, reject) => {

    const metadata = await getMetadata(null, traceId);

    const request = new ObjectId();
    request.setValue(objectId);
    
    const gateway = new RulesClient(ENDPOINT, null, null);
    
    gateway.delete(request, metadata, (err, res) => {

      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Error occurred while deleting rule ${objectId}: `);
        console.error(err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        console.log(`[${PREFIX}]: Deleting rule was successful.`);
        resolve(res);
      }
    })

  }) // end of promise
}
