<template>
  <div>
    <div class="flex flex-col">
      <div
        v-for="(emailList, emailListIndex) in emailLists"
        :key="`email-list-${emailListIndex}`"
        class="w-full border border-gray-300 rounded px-5 py-2 bg-white shadow-sm mb-4"
      >

        <div class="mt-2">
          <section class="w-full">

            <div class="flex justify-between">

              <h2 class="block text-base font-medium leading-6 text-gray-900">
                Group Name
              </h2>

              <div class="flex justify-start text-xs text-red-500">
              <button
                v-if="emailLists.length != 1"
                @click="removeEmailList(emailListIndex)"
                class="flex space-x-1 items-center hover:opacity-75"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Remove Group</span>
              </button>
            </div>
              
          </div>
            
            <!-- Group Name Here -->
            <!-- <div class="text-gray-700 rounded-md block w-full text-sm py-2 w-full my-2">
              Group {{emailListIndex+1}}  
            </div> -->
            <div class="col-span-full mt-2">
              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600">
                <input
                  v-model="groupNames[emailListIndex]"
                  @blur="addGroupName(emailListIndex)"
                  @input="onGroupNameUpdate(emailListIndex)"
                  type="text"
                  name="rulename"
                  id="rulename"
                  :class="[
                    groupNamesError[emailListIndex] && groupNamesError[emailListIndex] != '' 
                      ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'text-gray-900 placeholder-gray-300 focus:border-gray-500 focus:ring-gray-500 border-gray-300',
                    'rounded-md block w-full pr-10 focus:outline-none sm:text-sm bg-white px-3 py-2',
                  ]"
                />
              </div>
              <!-- Error Section -->
              <section
                v-if="groupNamesError[emailListIndex] != '' "
                class="mt-1 text-sm text-red-600"
              >
                {{ groupNamesError[emailListIndex] }}
              </section>
            </div>

          </section>
        </div>

        <section class="mt-5 mb-2">
          <h2 class="block text-base font-medium leading-6 text-gray-900">
            Email Addresses
          </h2>

        <div class="flex justify-between space-x-2 mt-1">
          <input
            v-model="formInput[emailListIndex]"
            @input="onUpdate(emailListIndex, formInput[emailListIndex])"
            @keypress.enter="
              addNewEmailToList(emailListIndex, formInput[emailListIndex])
            "
            type="text"
            :class="[
              hasError[emailListIndex]
                ? 'text-red-900 focus:border-red-500 focus:ring-red-500 border-red-300'
                : 'text-gray-900 focus:border-gray-500 focus:ring-gray-500 border-gray-300',
              'placeholder-gray-300 rounded-md block w-full pr-10 focus:outline-none sm:text-sm px-3 py-2',
            ]"
          />
          <button
            type="submit"
            @click="
              addNewEmailToList(emailListIndex, formInput[emailListIndex])
            "
            class="flex items-center space-x-1 text-sm disabled:opacity-75 disabled:cursor-default px-4 py-1.5 rounded flex items-center justify-center bg-blue-500 h-10 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>

            
          </button>
        </div>
        <section
          :class="[
            hasError[emailListIndex] ? 'visible' : 'invisible',
            'mt-1 text-sm text-red-600',
          ]"
        >
          {{ errorMessage[emailListIndex] }}
        </section>
        <div
          v-if="emailList.length > 0"
          class="flex py-2 flex-wrap space-x-2 -ml-2"
        >
          <span
            v-for="(email, emailIndex) in emailList"
            :key="email"
            class="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500 items-center ml-2 my-1"
          >
            <span>{{ email }}</span>
            <button
              @click="removeEmailFromList(emailIndex, emailListIndex)"
              type="button"
              class="group relative -mr-1 h-3 w-3 rounded-sm hover:bg-gray-300 text-center"
            >
              <span class="sr-only">Remove</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>

              <span class="absolute -inset-1" />
            </button>
          </span>
        </div>
        <div v-else class="py-2 text-xs text-gray-500 text-center w-full">
          <div
            class="border border-gray-300 h-20 flex items-center text-center justify-center"
          >
            Add email addresses above.
          </div>
        </div>
        </section>
        
      </div>
    </div>

    <div>
      <button
        :disabled="emailLists.length > limit"
        @click="addNewEmailList"
        class="disabled:opacity-25 disabled:pointer-events-none disabled:cursor-default bg-blue-500 text-sm text-white px-4 py-2 rounded hover:opacity-75 flex space-x-2 items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5 -ml-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span class="font-medium">Add New Group</span>
      </button>
    </div>

  </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps(["emailCombinationsList", "groupNameList"]);
const emits = defineEmits(["action", "actionGroupName"]);

const emailLists = ref(props.emailCombinationsList);
const groupNames = ref(props.groupNameList);
const oldGroupNames = ref([...props.groupNameList]);
const groupNamesError = ref([]);
const formInput = ref([]);
const hasError = ref([]);
const errorMessage = ref([]);
let limit = 9;

const onUpdate = (index, value) => {
  if (value == "") {
    errorMessage.value[index] = "";
    hasError.value[index] = false;
  }
};

const validate = (value, list) => {
  const regexEmail = new RegExp(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/
  );

  console.log("validating: ", value);

  if (!regexEmail.test(value)) {
    return {
      isValid: false,
      errorMessage: "You entered an invalid email.",
    };
  }
  return {
    isValid: true,
    errorMessage: "",
  };
};

// splits the email string
const splitEmails = (string) => {
  return string
    .split(/[,]|[;]|[\s]+/) // segregate the emails by terminators via Regex
    .filter((el) => el != ""); // remove empty values after segregation, if any
};

const addNewEmailToList = (listIndex, formValue) => {
  if (formValue == "" || formValue == undefined) {
    errorMessage.value[listIndex] = "You entered an empty string";
    hasError.value[listIndex] = true;
    return;
  }

  let values = splitEmails(formValue.toLowerCase());
  values = [...new Set(values)]; // removes duplicates
  console.log(values);

  if (values.length == 0) {
    errorMessage.value[listIndex] =
      "You entered an empty or invalid email address.";
    hasError.value[listIndex] = true;
    return;
  }

  let resultDupCheck = checkForDuplicates(values, emailLists.value[listIndex]);
  console.log(resultDupCheck);
  if (!resultDupCheck.isValid) {
    errorMessage.value[listIndex] = resultDupCheck.errorMessage;
    hasError.value[listIndex] = true;
    return;
  }

  let validations = evaluateInputs(values);
  if (validations.every((e) => e === true)) {
    // all are valuid
    errorMessage.value[listIndex] = "";
    hasError.value[listIndex] = false;
  } else {
    if (values.length > 1) {
      errorMessage.value[listIndex] =
        "One or more email addresses are invalid.";
      hasError.value[listIndex] = true;
    } else {
      errorMessage.value[listIndex] = "Invalid email address.";
      hasError.value[listIndex] = true;
    }
    return;
  }

  // if all goes well, we proceed normally

  values.forEach((value) => {
    let _emailLists = emailLists.value;
    let _emailList = _emailLists[listIndex];
    _emailList.push(value);
    _emailLists[listIndex] = _emailList;
    emailLists.value = _emailLists;
  });

  emits('action', emailLists.value)

  // Group Name
  let oldGroupNames = groupNames.value;
  oldGroupNames.push(`Group ${groupNames.value.length+1}`);
  groupNames.value = oldGroupNames;
  emits('actionGroupName', groupNames.value)


  errorMessage.value[listIndex] = "";
  hasError.value[listIndex] = false;
  formInput.value[listIndex] = "";
};

const addGroupName = (index) => {
  console.log(groupNames.value[index]);
  // validate here

  /**
   * Here we validate an input using the passed
   * function. This can be a function to validate
   * a simple string, domain, email or regex
   */
   let result = isValidName(groupNames.value[index], index);

  /**
   * If result is invalid, display the error
   * and terminate the function via "Gating".
   */
  if (!result.isValid) {
    console.error("invalid item")
    let _groupNamesError = groupNamesError.value;
    _groupNamesError[index] = result.errorMessage;
    groupNamesError.value = _groupNamesError;

    console.log(groupNamesError.value)

  }else{

    let _groupNamesError = groupNamesError.value;
    _groupNamesError[index] = '';
    groupNamesError.value = _groupNamesError;

    oldGroupNames.value = [...groupNames.value];

    emits('actionGroupName', groupNames.value)
  }
  
}


const onGroupNameUpdate = (index) => {
  /**
   * Here, we only check if the input is already empty
   * and remove any error message if it's the case.
   */
  if (groupNames.value[index].length == 0) {
    groupNamesError.value[index] == ''
  }
};


const addNewEmailList = () => {
  let emailList = new Array();
  let oldemailList = emailLists.value;
  oldemailList.push(emailList);
  emailLists.value = oldemailList;
  emits('action', emailLists.value)

  groupNames.value.push('Group '+(groupNames.value.length+1))
  
};

const removeEmailList = (index) => {
  let oldemailList = emailLists.value;
  oldemailList.splice(index, 1);
  emailLists.value = oldemailList;
  emits('action', emailLists.value)

  let _oldGroupNames = groupNames.value;
  _oldGroupNames.splice(index, 1);
  groupNames.value = _oldGroupNames;
  emits('actionGroupName', groupNames.value)

  oldGroupNames.value = [...groupNames.value];
};

const removeEmailFromList = (emailIndex, emailListIndex) => {
  let _emailLists = emailLists.value;
  let _emailList = _emailLists[emailListIndex];
  _emailList.splice(emailIndex, 1);
  _emailLists[emailListIndex] = _emailList;
  emailLists.value = _emailLists;
  emits('action', emailLists.value)
};


// VALIDATIONS

const evaluateInputs = (inputs) => {
  return inputs.map((input) => (validate(input).isValid ? true : false));
};

const checkForDuplicates = (inputs, list) => {
  let _duplicates = [];
  inputs.forEach((input) => {
    if (list.includes(input)) {
      _duplicates.push(input);
    }
  });

  if (_duplicates.length > 0) {
    return {
      isValid: false,
      errorMessage: "A duplicate item has been found.",
    };
  } else {
    return {
      isValid: true,
      errorMessage: "",
    };
  }
};


// validation
const isValidName = (item, index) => {

console.log(groupNames.value)
console.log(item)
console.log(groupNames.value.includes(item))
console.log(oldGroupNames.value.includes(item))

if (
  item.trim() == "" ||
  item == undefined ||
  item == null
) {
  return {
    isValid: false,
    errorMessage: "You entered an empty string",
  };
} else if (item.length < 4) {
  return {
    isValid: false,
    errorMessage: "Please input more than 3 characters.",
  };
} else if (oldGroupNames.value.includes(item) && oldGroupNames.value[index] != item) {
  return {
    isValid: false,
    errorMessage: "This name already exists.",
  };
} else {
  return {
    isValid: true,
  };
}
};

</script>