<template>
  <div class="px-8 py-6">
    <div class="flex flex-col space-y-1">
      <TabHeading :title="TAB_TITLE" :description="TAB_DESCRIPTION" />
      <!-- <CreateRuleOptions
        :class="
          SessionStore.state == STATE.WAITING ||
          SessionStore.state == STATE.INITIALIZING
            ? 'pointer-events-none cursor-default opacity-50 animate-pulse'
            : ''
        "
      /> -->
      <RulesList
        :class="
          SessionStore.state == STATE.WAITING ||
          SessionStore.state == STATE.INITIALIZING
            ? 'pointer-events-none cursor-default opacity-50 animate-pulse'
            : ''
        "
      />

      <RuleSlideOver />

    </div>
  </div>
  <ErrorNotification />
</template>
<script setup>
import { STATE } from "./data/enums";
import ROUTER from "@/router";

// Adapter and User classes, etc.
import { activate } from '@/adapter'
import { Role as ROLE } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { v4 } from "uuid";

// Error Handling
import ErrorNotification from "./components/ErrorNotification.vue";

// Components
import TabHeading from "./components/TabHeading.vue";
// import CreateRuleOptions from "./components/CreateRuleOptions.vue";
import RulesList from "./components/RulesList.vue";
import RuleSlideOver from "./components/RuleSlideOver.vue";

// Store Dependencies
import { useV3RulesStore } from "./store/rulesStore.js";
import { useV3SessionStore } from "./store/sessionStore.js";
const RulesStore = useV3RulesStore();
const SessionStore = useV3SessionStore();

// UI Strings
const TAB_TITLE = "Rules";
const TAB_DESCRIPTION =
  "Rules are customer-defined email addresses, domain names, and email content that trigger a safe, warn, or block action when a positive match is detected.";


/**
 * @TODO
 * Move this to the router and use store for this.
 * Make this reusable
 */
const adminGuard = async () => {
  return new Promise(async (resolve, reject) => {
    activate(v4())
      .then( async result => {
        console.log({result})
        console.log(result.getRole())
        if(result.getRole() === ROLE.ROLE_ADMIN){
          // If admin, proceed normally
          console.log("%cYou have the right to view this page.", "color: #B4D455")
          resolve();
        }else if(result.getRole() === ROLE.ROLE_USER){
          // Else if user, redirect immediately to /endpoints page
          console.log("%cYou don't have the right to view this page. Redirecting...", "color: #FE2020")
          ROUTER.push({ path: '/integrations' })
        }
      })          
  });
}

const initialize = async () => {
  await adminGuard();
  try {
    // TODO: activate and admin guard
    SessionStore.setToInitializeState();
    await SessionStore.setTraceId();
    await RulesStore.pushListRule(SessionStore.traceId);
    SessionStore.setToReadyState();
  } catch (err) {
    SessionStore.setToErrorState();
    SessionStore.setError({
      message: err.message,
      code: err.code,
    });
  }
};
initialize();
</script>