<template>
  <div class="col-span-full">
    <!-- Items List Section -->
    <section class="mt-0 py-0 space-y-0.5 text-sm text-gray-600">
      <!-- <section class="my-4 px-3 py-3 border-gray-300 rounded-md border space-y-4 text-sm text-gray-600"> -->

      <div
        v-for="(item, index) in options"
        :key="index"
        class="relative flex items-start"
      >
        <div class="flex h-6 items-center">
          <button
            @click="updateSelectedOptions(item)"
            type="button"
            :class="[
              modelValue.includes(item) ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
            ]"
            role="switch"
            aria-checked="false"
          >
            <span class="sr-only">Use setting</span>
            <span
              :class="[
                modelValue.includes(item) ? 'translate-x-3' : 'translate-x-0',
                'pointer-events-none relative inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              ]"
            >
            </span>
          </button>
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="candidates" class="text-gray-700">
            {{ locationStringMap(enumToString(options, item)) }}
          </label>
        </div>
      </div>
    </section>

    <!-- Error Section -->
    <section v-if="modelValue.length == 0" class="mt-1 text-sm text-red-600">
      {{ errorMessage }}
    </section>
  </div>
</template>
<script setup>
/**
 * Declare dependencies
 */
import { ref } from "vue";
import { enumToString } from "@/utilities";

/**
 * Define props and emits
 */
const props = defineProps(["modelValue", "options"]);
const emits = defineEmits(["update:modelValue", "action"]);

/**
 * UI variables
 */
const errorMessage = ref("Select at least one location.");

/**
 * Update the selected options here.
 */
const updateSelectedOptions = (item) => {
  let _items = props.modelValue;

  // toggle the option here
  if (_items.includes(item)) {
    let i = _items.findIndex((e) => e == item);
    _items.splice(i, 1);
  } else {
    _items.push(item);
  }

  // emit the new value
  emits("update:modelValue", _items);
  emits("action", _items);
};

// TODO: specific only to location, MUST BE STANDARDIZED
const locationStringMap = (location) => {
  switch (location) {
    case "CONTENT_LOCATION_SUBJECT":
      return "Subject";
      break;

    case "CONTENT_LOCATION_BODY":
      return "Body";
      break;

    case "CONTENT_LOCATION_FILENAMES":
      return "Attachment Filename";
      break;

    default:
      return location;
      break;
  }
};
</script>